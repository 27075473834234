import React from 'react';
import './App.css';
import Nav from './components/nav.js';
import Slider from './components/Slider.js';
import Services from './components/services.js'
import Experience from './components/experience.js'
import About from './components/about.js';
import Partners from './components/partners.js';
import Team from './components/team.js';
import Contact from './components/contact.js';
import Footer from './components/footer.js'
import CookieConsent from "react-cookie-consent";
import { withNamespaces } from 'react-i18next';
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          cookiesAccepted: false,
        };
    }

    loadCookies = () => { 
      const gAnalyticsScript = document.createElement("script");
      gAnalyticsScript.src = "https://www.googletagmanager.com/gtag/js?id=UA-54760994-2";
      gAnalyticsScript.async = true;
      document.body.appendChild(gAnalyticsScript);

      const gaConfigScript = document.createElement("script");
      gaConfigScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'UA-54760994-2', {
          'anonymize_ip': true,  
          'cookie_flags': 'SameSite=Strict',  
          'storage': 'none'
    });
    `;
    document.body.appendChild(gaConfigScript)

    const fbPixelScript = document.createElement("script");
    fbPixelScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '567758963382750');
      fbq('track', 'PageView');
    `;
    document.body.appendChild(fbPixelScript);


    }

    render(){
        const {  dati, loading, items } = this.state;

            return (
              <div className="App">
              <CookieConsent
              
        
                enableDeclineButton={true}
                onAccept={(byScroll) => {
                  console.log('User accepted cookies');
                    this.setState({cookiesAccepted: true}, () => { 
                      this.loadCookies();
                    });
                  }}
                onDecline={(byScroll)=> { 
                  console.log('user has declined cookies'); 
                    this.setState({cookiesAccepted: false})
                }}
                location="bottom"
                buttonText="Apstiprināt" 
                declineButtonText= "Noraidīt"
                buttonWrapperClasses="cookie-buttons-wrapper"
                >
                
                Šī vietne izmanto sīkdatnes, lai uzlabotu lietošanas pieredzi un optimizētu tās darbību. <a href="https://privatums.dtg.lv" target="_blank">Lasīt vairāk</a>
            </CookieConsent>
                    <div style={{overflow: 'hidden'}}>
                      {/* Navigācija */}
                        <Nav />
                      {/* Headeris / Videoklipa vieta*/}
                        <Slider />
                      {/* Pakalpojumi*/}
                        <Services />
                      {/* Portfolio*/}
                        <Experience />
                      {/* Par Mums */}
                      {/* Sadarbības Partneri */}
                      {/* Komanda*/}
                        <Team />
                      {/* Sazināties  */}
                        <Contact />
                      {/* Footer*/}
                         <Footer />

                    </div>
                </div>
            );
        }
    }



export default withNamespaces()(App);
