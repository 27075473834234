import React from 'react';
import i18n from '../i18n';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default class Slider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: 1,
        };
    }

    changeLanguage(lng) {
        console.log('CHANGE')
        i18n.changeLanguage(lng);

    }
componentDidMount(){
    this.setState({language: i18n.language})
}
    render(){
        const options = {
            items:1,
            autoplay: true,
            autoplayTimeout:4000,
            loop: true,
            className:'owl-carousel',
            autoplaySpeed:700,
            navSpeed:700,
            dotsSpeed:700,
            lazyLoad: true,
            lazyContent: true,
};
        return(





            <div className='video-container' style={{paddingTop: '5%'}}>
                <video id='videobg' muted loop playsInline autoPlay>
                  <source src="../workhour.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

            </div>





        )
    }
}
